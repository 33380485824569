@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

.home,
.santander,
.products {
  display: flex;
  flex-direction: column;
  height: 110vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  background-color: #eee;
  color: black;
}

iframe {
  border: none;
  border-radius: 4px;
}

/* Navbar CSS */
.navbar {
  background-color: #eee;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  color: black;
}

.nav-menu {
  background-color: #eee;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 450ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #151414;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #eee;
  color: black;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #eee;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 16px;
}

.input-group {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
}

.input-group p {
  font-size: medium;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-group input {
  width: 50%;
  height: 20px;
  padding: 4px;
  box-sizing: border-box;
}

.input-row {
  display: flex;
  gap: 50px;
  align-items: center;
}